@use '../../partials/colors' as *;
@use '../../partials/mixins' as *;
@use '../../partials/type' as *;
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=Charm&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html,
body {
    overflow-x: hidden;
}

h2 {
    margin: 0;
    padding: 0;
}

h3 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    margin: 0;
}

.projects {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    justify-content: flex-start;
    align-items: center;
    scroll-snap-align: start; 
 
    &__section-title {
        color: $main-text-color;
        margin: 2rem;
        padding-left: 2rem;
        font-size: 4rem;
        letter-spacing: 4px;
        width: 100%;
        text-align: left;
        margin-top: 7rem;

        @include tablet {
            padding-left: 6rem;
            margin-top: 10rem;
        }
    }

    &__accordian {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 65%;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        mask-image: inear-gradient(180deg, #000 60%, transparent);
        -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
    }

    &__accordian::-webkit-scrollbar {
        display: none;
    }

    &__line {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    &__title {
        font-size: 1rem;
        color: $main-text-color;
        width: 80%;
        padding-left: 1rem;

        @include tablet {
            font-size: 1.5rem;
            width: 70%;
        }
    }

    &__date {
        color: $main-text-color;
    }


    &__content {
        max-height: 0;
        overflow: hidden;
        transition: all -.5s cubic-bezier(0,1,0,1);
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &__content-show {
        height: auto;
        max-height: 9999px;
        transition: all -.5s cubic-bezier(1,0,1,0);
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: none;
    }

    &__build {
        color: $main-text-color;
        margin-left: 0;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 70%;
        letter-spacing: .7px;
        line-height: 1.5rem;

        @include tablet {
            width: 30%;
        }
    }

    &__images {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-top: 1rem;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    &__images::-webkit-scrollbar {
        display: none;
    }

    &__item {
        border-top: 1px solid $main-text-color;
        width: 90vw;
        padding: .55rem;
        cursor: pointer;
        overflow-x: none;

        &--last {
            border-top: 1px solid $main-text-color;
            width: 100vw;
            border-bottom: 1px solid $main-text-color;
            width: 90vw;
            padding: .35rem;
            cursor: pointer;
            margin-bottom: 9rem;
        }
    }

}

.image {
    max-height: 8rem;
    max-width: 18rem;
    margin: 1rem;
    border: .5px solid white;

    @include tablet {
        max-height: 15rem;
        max-width: 25rem;
    }
}