@use '../../partials/colors' as *;
@use '../../partials/mixins' as *;
@use '../../partials/type' as *;
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=Charm&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


@mixin default-theme() {
    --background-color: $main-background-color;
    --text-color: $main-text-color
}

@mixin color-theme() {
    --backgrond-color: rgb(32, 71, 41);
    --text-color: rgb(17, 17, 17);
}


html,
body {
    @include color-theme();
    &.is_inverted {
        @include default-theme()
    }
    @media (prefers-color-scheme: default) {
        @include default-theme();
        &.is_inverted {
            @include color-theme()
        }
    }
    width: 100vw;
    height: 100vh;
    background-color: $main-background-color;
    margin: 0;
    font-family: 'Roboto';
    font-weight: 700;
}

li {
    text-decoration: none;
    list-style: none;
    padding-right: .5rem;

    @include tablet {
        padding-right: 1rem;
    }
}

ul {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    margin: 1rem 0 0 0;
}

p {
    margin-top: 1rem;
}

.mainblock {
    width: 100vw;
    height: 100vh;
    background-color: $main-background-color;
    margin: 0 0 1rem 0;
    scroll-snap-align: start; 

    &__top {

        width: 100%;
        color: $main-text-color;
        display: flex;
        flex-direction: row;
        height: 50%;

        @include tablet {
            height: 30%;

        }

        @include desktop {
            height: 22%;
        }
    }


    &__tabs {
        text-decoration: none;
        list-style: none;
        width: 32%;
        display: flex;
        flex-direction: row;
        font-size: 1.3rem;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
        visibility: hidden;

        @include tablet {
            width: 38%;
            font-size: 2rem;
            letter-spacing: .5px;
        }

        @include desktop {
            width: 45%;
        }
    }

    &__info {
        width: 55%;
        padding-right: .25rem;
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            padding-right: 6rem;
            height: 100%;
        }
    }

    &__blurb {
        width: 90%;
        font-size: 1.2rem;
        padding-left: .25rem;
        border-left: 1px solid $main-text-color;
        letter-spacing: .5px;
        margin-left: 1rem;

        @include tablet {
            font-size: 1.3rem;
            width: 58%;
            margin-left: 2rem;
            padding-left: 1rem;
        }
    }
    &__contact {
        width: 70%;
        font-size: 1.2rem;
        margin-left: 1rem;
        padding-left: .25rem;
        padding-top: 1rem;
        border-left: 1px solid $main-text-color;
        overflow-wrap: break-word;

        @include tablet {
            font-size: 1.3rem;
            width: 30%;
            padding-top: 0;
            padding-left: 1rem;
            overflow-wrap: normal;
        }
    }

    &__bottom {
        width: 100vw;
        position: relative;

        @include desktop {
          height: 75%;
        }

    }

    &__title {

        color: $main-text-color;
        opacity: .9;
        padding-left: 1rem;
        font-size: 4.5rem;
        padding-top: 9rem;
        line-height: 4.5rem;

        @include tablet {
            font-size: 9rem;
            margin-top: 6rem;
            line-height: 10rem;
            

        }
        @include desktop {
            font-size: 13rem;
            font-weight: 900;
            margin-top: 0;
            letter-spacing: .5rem;
            line-height: 14rem;
        }
    }
}    


*, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
   }
  
  
  .mouse_scroll {

    display: none;

    @include tablet {
      //   display: block;
        position: absolute;
        bottom: -2.5rem;
        right: .5rem;
        margin: 0 auto;
        width: 24px;
        height: 100px;
        margin-right: 1rem;
        margin-top: 4rem;
      //   margin-top: 125px;
    }
  }
  
  
  .m_scroll_arrows
  {
    display: block;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
     
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin: 0 0 3px 4px;
    
    width: 16px;
    height: 16px;
  }
  
  
  .unu
  {
    margin-top: 1px;
  }
  
  .unu, .doi, .trei
  {
      -webkit-animation: mouse-scroll 1s infinite;
      -moz-animation: mouse-scroll 1s infinite;
      animation: mouse-scroll 1s infinite;
    
  }
  
  .unu
  {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;
    
    animation-direction: alternate;
    animation-delay: alternate;
  }
  
  .doi
  {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;
    
    animation-delay: .2s;
    animation-direction: alternate;
    
    margin-top: -6px;
  }
  
  .trei
  {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;
    
    animation-delay: .3s;
    animation-direction: alternate;
    
    
    margin-top: -6px;
  }
  
  .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid white;
    top: 170px;
  }
  
  .wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;
    
    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    -webkit-border-radius: 8px;
            border-radius: 8px;
  }
  
  .wheel {
    -webkit-animation: mouse-wheel 0.6s linear infinite;
    -moz-animation: mouse-wheel 0.6s linear infinite;
    animation: mouse-wheel 0.6s linear infinite;
  }
  
  @-webkit-keyframes mouse-wheel{
     0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
      transform: translateY(6px);
    }
  }
  @-moz-keyframes mouse-wheel {
    0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
  }
  @-o-keyframes mouse-wheel {
  
     0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
  }
  @keyframes mouse-wheel {
  
     0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
  }
  
  @-webkit-keyframes mouse-scroll {
  
    0%   { opacity: 0;}
    50%  { opacity: .5;}
    100% { opacity: 1;}
  }
  @-moz-keyframes mouse-scroll {
  
    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
  }
  @-o-keyframes mouse-scroll {
  
    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
  }
  @keyframes mouse-scroll {
  
    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
  }