@use '../../partials/colors' as *;
@use '../../partials/mixins' as *;
@use '../../partials/type' as *;

.container {
    width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
}

#video {
    width: 70%;
    height: auto;
    margin: .5rem;

    @include tablet {
        width: 35%;
    }
}