*,
*::before,
*::after {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}

.bigger {
  background: rgb(247, 245, 245);
  min-height: 100vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  scroll-snap-align: start;
}

.containered {
  width: 400px;
  height: 400px;
  border-radius: 4px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-perspective: 800px;
  perspective: 800px;
  -webkit-perspective-origin: top right;
  perspective-origin: top right;
}

.cube {
  transform: rotate3d(-15, 25, 5, 50deg);
  position: relative;
  width: 200px;
  height: 200px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  animation: cube 60s ease-in-out infinite alternate-reverse;
}

.side {
  color: #000000;
  position: absolute;
  width: 100%;
  height: 100%;

  text-align: justify;
  font-size: .7rem;
  overflow: hidden;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  background: rgb(71,71,71);
background: radial-gradient(circle, rgba(236, 236, 236, 0) 0%, rgba(236, 236, 236, 0.622) 100%);
}

.front {
  -webkit-transform: translateZ(100px);
  transform: translateZ(100px);
}

.back {
  -webkit-transform: translateZ(-100px);
  transform: translateZ(-100px);
}

.left {
  -webkit-transform: rotateY(90deg) translateZ(100px);
  transform: rotateY(90deg) translateZ(100px);
}

.right {
  -webkit-transform: rotateY(-90deg) translateZ(100px);
  transform: rotateY(-90deg) translateZ(100px);
}

.top {
  -webkit-transform: rotateX(90deg) translateZ(100px);
  transform: rotateX(90deg) translateZ(100px);
}

.bottom {
  -webkit-transform: rotateX(-90deg) translateZ(100px);
  transform: rotateX(-90deg) translateZ(100px);
}

@keyframes cube {
  /*transform: rotate3d(-15, 25, 5, 50deg);*/
  25% {
    transform: rotate3d(5, 5, 5, 40deg);
  }
  50% {
    transform: rotate3d(-10, 5, 15, 60deg);
  }
  75% {
    transform: rotate3d(20, -20, 5, 70deg);
  }
  100% {
    transform: rotate3d(10, 0, 10, 80deg);
  }
}

