@use './partials/colors' as *;
@use './partials/mixins' as *;
@use './partials/type' as *;
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=Charm&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


* {
    box-sizing: border-box;
}

html,
body {
    height:100vh;
    overflow: hidden;
    overflow-x: hidden;
}

.main {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: none;
    }
    
}


.section {
    scroll-snap-align: start; 
    height: 100vh;
}

li {
    cursor: pointer;
}

.tabs {
        position: absolute;
        // top: 1rem;
        // bottom: 1rem;
        color: $main-text-color;
        background-blend-mode: difference;
        text-decoration: none;
        list-style: none;
        width: 32%;
        display: flex;
        flex-direction: row;
        font-size: 1.3rem;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;

        @include tablet {
            width: 38%;
            font-size: 2rem;
            letter-spacing: .5px;
        }

        @include desktop {
            width: 45%;
        }
}