@use '../../partials/colors' as *;
@use '../../partials/mixins' as *;
@use '../../partials/type' as *;
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=Charm&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body,
html {
    width: 100vw;
    height: 100vh;
}

.contact {
    width: 100vw;
    height: 100vh;
    scroll-snap-align: start; 
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        color: $main-text-color;
        font-size: 4rem;
        padding: 0;
        letter-spacing: 2px;
        margin-top: 7rem;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__name {
        width: 20rem;
        height: 3.5rem;
        font-family: 'Roboto';
        font-size: 1.3rem;
        color: white;
        background-color: transparent;
        border: 1px solid white;
        box-shadow: none;
        padding: .5rem;
        margin: 3rem 1rem .5rem 1rem;

        @include tablet {
            width: 28rem;
            height: 3.5rem;
            margin: 6rem 1rem 1rem 1rem
        }

    }

    &__email {
        width: 20rem;
        height: 3.5rem;
        font-family: 'Roboto';
        font-size: 1.3rem;
        color: white;
        background-color: transparent;
        border: 1px solid white;
        box-shadow: none;
        padding: .5rem;
        margin: .5rem 1rem .5rem 1rem;

        @include tablet {
            width: 28rem;
            height: 3.5rem;
            margin: 1rem;
        }
    }

    &__message {
        width: 20rem;
        height: 13rem;
        font-family: 'Roboto';
        font-size: 1.3rem;
        color: white;
        background-color: transparent;
        border: 1px solid white;
        box-shadow: none;
        padding: .5rem;
        margin: .5rem 1rem .5rem 1rem;


        @include tablet {
            width: 28rem;
            height: 8rem;
            margin: 1rem;
        }
    }

    &__button {
        width: 10rem;
        height: 3rem;
        font-size: 1rem;
        cursor: pointer;
        margin: 1rem;
        border-radius: 1px;
        background-color: white;
        color: black;
    }
}